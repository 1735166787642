import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { ConditionalFormForSchema } from "@lookinglocal/react-jsonschema-form-extensions";
import * as AppWidgets from "./widgets/AppWidgets";
import { useAuthContext } from "../auth/AuthContextProvider";

const FormForSchemaModal = ({
	modalTitle,
	bodyClass,
	isLoading,
	errorMessage,
	onModalClose,
	...rest
}) => {
	let onSubmitExecutor = null;
	const { getTokenSilently } = useAuthContext();

	// we need to inject both the base url and the auth interceptor into
	// any rendered forms, otherwise lookups don't work correctly
	function axiosRequestInterceptor(config) {
		return getTokenSilently()
			.then((token) => {
				config.headers = config.headers || {};
				config.headers.Authorization = `Bearer ${token.raw}`;
				return config;
			})
			.catch(() => {
				return config;
			});
	}

	function submit() {
		onSubmitExecutor && onSubmitExecutor();
	}

	return (
		<Modal
			data-testid="form-for-schema-modal"
			centered
			show
			onHide={() => onModalClose(null)}
			backdrop="static"
			animation={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h1>{modalTitle}</h1>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={bodyClass || "form-page-modal"}>
				{isLoading && <Spinner animation={"border"} variant={"primary"} />}
				{errorMessage && (
					<div className="alert alert-danger" data-testid="modal-error">
						{errorMessage}
					</div>
				)}
				{!isLoading && (
					<ConditionalFormForSchema
						appWidgets={AppWidgets.appWidgets}
						onSubmitExecutorChange={(fn) => (onSubmitExecutor = fn)}
						isSubmitVisible={false}
						isCancelVisible={false}
						axiosBaseUrl={process.env.REACT_APP_SERVER}
						axiosInterceptor={axiosRequestInterceptor}
						{...rest}
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<button
					type="button"
					data-testid="modal-cancel"
					className="btn btn-outline-secondary btn-sm pl-5 pr-5 mr-auto"
					onClick={() => onModalClose(null)}
				>
					Cancel
				</button>
				<button
					type="button"
					data-testid="modal-submit"
					disabled={isLoading}
					className="btn btn-primary btn-sm pl-5 pr-5"
					onClick={() => submit()}
				>
					Submit
				</button>
			</Modal.Footer>
		</Modal>
	);
};

FormForSchemaModal.propTypes = {
	modalTitle: PropTypes.string.isRequired,
	onModalClose: PropTypes.func.isRequired,
	bodyClass: PropTypes.string,
	...ConditionalFormForSchema.propTypes,
};

export default FormForSchemaModal;
