import { FC, useEffect, useState } from 'react';
import { useAppConfiguration } from '../contexts/AppConfiguration';
import { appFeatureFlag, AppFeatureFlag } from '../types';

/**
 * Props for the feature flag component
 */
export interface FeatureFlaggedProps {
  /**
   * The feature used to activate any child components
   */
  feature: AppFeatureFlag;
  /**
   * The child components, will not be rendered if the feature flag is not present
   */
  children?: React.ReactNode;
}

/**
 * Wrap any UX components in this if you want them to be enabled/disabled
 * based on the presence of a specific feature flag within the current application
 * configuration
 */
export const FeatureFlagged: FC<FeatureFlaggedProps> = (props) => {
  const appConfig = useAppConfiguration();
  const [display, setDisplay] = useState(false);

  // wait for the config to load and check the flag
  useEffect(() => {
    if (appConfig.loaded) {
      const haveFeatureFlag = appFeatureFlag(appConfig, props.feature);
      setDisplay(haveFeatureFlag);
    }
  }, [appConfig.loaded, appConfig, props.feature]);

  if (display) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};

/**
 * Wrap any UX components in this if you want them to be enabled/disabled
 * based on the presence of a specific feature flag within the current application
 * configuration
 */
export const NotFeatureFlagged: FC<FeatureFlaggedProps> = (props) => {
  const appConfig = useAppConfiguration();
  const [display, setDisplay] = useState(false);

  // wait for the config to load and check the flag
  useEffect(() => {
    if (appConfig.loaded) {
      const haveFeatureFlag = appFeatureFlag(appConfig, props.feature);
      setDisplay(!haveFeatureFlag);
    }
  }, [appConfig.loaded, appConfig, props.feature]);

  if (display) {
    return <>{props.children}</>;
  } else {
    return <></>;
  }
};
