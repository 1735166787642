import React from "react";
import { ConditionalFormForSchema } from "@lookinglocal/react-jsonschema-form-extensions";
import { latestCaseConfiguration } from "../shared/types";
import { useAuthContext } from "../auth/AuthContextProvider";

const CaseDetailsForm = (props) => {
	const appConfig = props.appConfig;
	const caseRef = props.caseRef;
	const caseConfig = latestCaseConfiguration(appConfig, caseRef);
	const { getTokenSilently } = useAuthContext();

	// we need to inject both the base url and the auth interceptor into
	// any rendered forms, otherwise lookups don't work correctly
	function axiosRequestInterceptor(config) {
		return getTokenSilently()
			.then((token) => {
				config.headers = config.headers || {};
				config.headers.Authorization = `Bearer ${token.raw}`;
				return config;
			})
			.catch(() => {
				return config;
			});
	}

	return (
		<ConditionalFormForSchema
			schema={caseConfig.form.schema}
			uiSchema={caseConfig.form.uiSchema}
			rules={caseConfig.form.rules}
			axiosBaseUrl={process.env.REACT_APP_SERVER}
			axiosInterceptor={axiosRequestInterceptor}
			{...props}
		/>
	);
};

export default CaseDetailsForm;
