import React, { useEffect, useReducer } from 'react';
import { FC } from 'react';
import { PageNav } from '../shared/Page';
import { useParams } from 'react-router-dom';
import { useAppConfiguration } from '../shared/contexts/AppConfiguration';
import {
  compileFormRunnables,
  taskConfigurationVersion
} from '../shared/types';
import { ConditionalFormForSchema } from '@lookinglocal/react-jsonschema-form-extensions';
import _ from 'lodash';
import { valueByJsonPointerPath } from '../shared/functions';
import { useAuthContext } from '../auth/AuthContextProvider';

export interface TaskFormPreviewProps { }

/**
 * Mapping of path parameters
 */
interface TaskFormPreviewPathParams {
  taskRef?: string;
  taskVersion?: string;
}

interface TaskFormPreviewState {
  initialised: boolean;
  formTitle?: string;
  formData?: any;
  schema?: any;
  uiSchema?: any;
  rules?: any;
  actions?: any;
  handler?: any;
  handlerContext?: any;
}

/**
 * Shiny new component for the previewing of forms, with support for dynamic form
 * handler functions and associated dynamic rules
 * @param props
 * @constructor
 */
export const TaskFormPreview: FC<TaskFormPreviewProps> = (props) => {
  const appConfig = useAppConfiguration();
  const params = useParams() as TaskFormPreviewPathParams;
  const [state, dispatch] = useReducer(reducer, {
    initialised: false
  });
  const { getTokenSilently } = useAuthContext() as { getTokenSilently: any };

  // we need to inject both the base url and the auth interceptor into
  // any rendered forms, otherwise lookups don't work correctly
  function axiosRequestInterceptor(config: any) {
    return getTokenSilently()
      .then((token: any) => {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token.raw}`;
        return config;
      })
      .catch(() => {
        return config;
      });
  }

  // state reducer
  function reducer(state: TaskFormPreviewState, action: any) {
    const { type, payload } = action;
    switch (type) {
      case 'initialise': {
        return {
          ...state,
          ...payload
        };
      }
      case 'updateFormData': {
        return {
          ...state,
          formData: payload
        };
      }
    }
    return state;
  }

  useEffect(() => {
    if (appConfig.loaded && !state.initialised) {
      if (params.taskRef && params.taskVersion) {
        const taskConfig = taskConfigurationVersion(
          appConfig,
          params.taskRef,
          parseInt(params.taskVersion)
        );
        const formTitle = valueByJsonPointerPath(
          '/title',
          taskConfig.form.schema
        );
        const [actions, handler, handlerContext] =
          compileFormRunnables(taskConfig);
        dispatch({
          type: 'initialise',
          payload: {
            initialised: true,
            formTitle: formTitle,
            formData: {},
            schema: _.cloneDeep(taskConfig.form.schema),
            uiSchema: _.cloneDeep(taskConfig.form.uiSchema),
            rules: _.cloneDeep(taskConfig.form.rules),
            actions: actions,
            handler: handler,
            handlerContext: handlerContext
          }
        });
      }
    }
  }, [
    appConfig,
    appConfig.loaded,
    params.taskRef,
    params.taskVersion,
    state.initialised
  ]);

  const updateFormData = (formData: any) => { };

  const onChange = (formData: any, schema: object) => {
    dispatch({ type: 'updateFormData', payload: formData });
  };

  const onRulesChange = (
    rules: object[],
    schema: object,
    uiSchema: object
  ) => { };

  return (
    <div className={`container-fluid px-xl-5 pb-2 pb-sm-5`}>
      <PageNav
        title={`Form Preview: ${state.formTitle ? state.formTitle : 'Loading'}`}
        translate={false}
      >
        <></>
      </PageNav>
      {!state.initialised && <div>Not yet initialised</div>}
      {state.initialised && (
        <div id="form-container" className="form-container">
          <ConditionalFormForSchema
            schema={state.schema}
            uiSchema={state.uiSchema}
            rules={state.rules}
            values={state.formData}
            extraActions={state.actions}
            formHandler={state.handler}
            onChange={onChange}
            onRulesChange={onRulesChange}
            axiosBaseUrl={process.env.REACT_APP_SERVER}
            axiosInterceptor={axiosRequestInterceptor}
            formContext={{
              formData: state.formData,
              updateFormData: updateFormData
            }}
          ></ConditionalFormForSchema>
        </div>
      )}
    </div>
  );
};
