import _ from 'lodash';

/**
 * Typed structure for application-level feature flags
 */
export type AppFeatureFlags = {
  [key in AppFeatureFlag]: boolean;
};

/**
 * Set of available/known feature flags
 */
export type AppFeatureFlag = 'dev' | 'externalSearch' | 'hideUserGuide';

/**
 * Top-level application level meta-data
 */
export interface AppMetaData {
  /**
   * Whether the app is currently in maintenance mode
   */
  maintenanceMode: boolean;

  /**
   * Any currently configured feature flags
   */
  featureFlags?: AppFeatureFlags;
}

/**
 * Role metadata, including a list of permissions
 */
export interface Role {
  authority: string;
  description: string;
  label: string;
  permissions: string[];
}

/**
 * Used for column displays within search etc...
 */
export interface DisplaySpecification {
  title?: string;
  titlePointer?: string;
  valuePointer: string;
  clientFunc?: string;
  serverFunc?: string;
}

export interface SummaryDisplayGroupSpecification {
  title: string;
  expandOnLoad?: boolean;
  displaySpecifications: DisplaySpecification[];
}

/**
 * The configuration for a specific case *version*.  Each case configuration comprises:
 *
 * 1. A form configuration for the case form
 * 2. A {@link CaseMetaData} structure containing details and UI hints for the case type
 * 3. A root location which is unused within the UI
 */
export interface CaseConfiguration {
  ref: string;
  version: number;
  form: FormConfiguration;
  meta: CaseMetaData;
  root: string;
}

/**
 * The configuration for a specific task *version*.  Each task configuration comprises:
 *
 * 1. A form configuration for the task form
 * 2. A {@link CaseMetaData} structure containing details and UI hints for the task type
 * 3. A root location which is unused within the UI
 */
export interface TaskConfiguration {
  ref: string;
  version: number;
  form: FormConfiguration;
  meta: TaskMetaData;
  root: string;
}

/**
 * Used to determine selectivity of tasks based on rules run at the server
 */
export interface SelectableTaskConfiguration {
  selectable: boolean;
  taskConfig: TaskConfiguration;
  requires: string[];
}

/**
 * A {@link CaseConfiguration} or {@link TaskConfiguration} may have a form associated with it. This structure
 * contains the information relating to that form, including:
 *
 * 1. An optional string containing some server-sourced JS actions (actions.js)
 * 2. An optional string containing some server-source JS functions (functions.js)
 * 3. An optional string containing a form handler function for the form (formHandler.js)
 * 4. An optional map of guidance structures, used when compositing the UI for the form
 * 5. An optional set of rules that define how form fields are displayed/hidden based on input
 * 6. The JSON schema for the form
 * 7. The JSON ui schema for the form
 */
export interface FormConfiguration {
  actions: string | null;
  globalFormFunctions: string | null;
  formHandler: string | null;
  guidance: { [key: string]: string } | null;
  rules: { [key: string]: any }[] | null;
  schema: { [key: string]: any };
  uiSchema: { [key: string]: any };
}

/**
 * This is really just here for convenience at the moment, until there is some
 * more generic way of expressing available task statuses.  Changing this
 * beyond the "hard-coded" three statuses at the moment would be a major
 * refactoring/migration effort
 */
export type ValidTaskStatus = 'OPEN' | 'STOPPED' | 'DONE';

/**
 * A task status representation
 */
export interface TaskStatus {
  label: ValidTaskStatus;
  requiresValidForm: boolean;
}

/**
 * A task dependency
 */
export interface TaskDependency {
  requires: string;
  status?: TaskStatus;
}

/**
 * Links a specific task to a given case type.  Within this structure,
 * it can be specified whether a given task is an "assessment" task, and whether
 * a task may be only single-instanced within a given case instance.
 *
 * The combination of {@link ref} and {@link version} are used to uniquely identify
 * a corresponding {@link TaskConfiguration}
 */
export interface CaseTask {
  assessment: boolean;
  ref: string;
  singleton: boolean;
  version: number;
  order?: number;
  template?: boolean;
  dependencies: TaskDependency[] | null;
}

/**
 * Defines a possible status for a given {@link CaseConfiguration}
 */
export interface CaseStatus {
  colour: string;
  default: boolean;
  description?: string;
  id: string;
  label: string;
  requiredForms: string[] | null;
  requiredRoles: string[] | null;
  terminating: boolean;
  trash: boolean;
  showInSearch?: boolean;
}

/**
 * Case label representation
 */
export interface CaseLabelDescriptor {
  label: string;
  value: string;
}

/*
 * Allowed case feature flags
 *
 * - promptForProfileInAssessment enables/disables forces a prompt for profile re-entry during assessment flows
 * - importable flags either a case or a task as being importable
 */
export type CaseFeatureFlag = 'promptForProfileInAssessment' | 'importable';

/**
 * Capture feature flags for a case
 */
export type CaseFeatureFlags = {
  [K in CaseFeatureFlag]?: boolean;
};

/**
 * The top-level metadata describing a case, it's list of {@link CaseStatus}s, and
 * it's list of {@link CaseTask}s.
 *
 * Cases are uniquely identified by a combination of {@link ref} and {@link version}
 */
export interface CaseMetaData {
  labels: CaseLabelDescriptor[] | null;
  statuses: CaseStatus[];
  messages: object;
  titleDisplaySpecification?: DisplaySpecification;
  searchDisplaySpecifications?: DisplaySpecification[];
  summaryDisplayGroups?: { [key: string]: SummaryDisplayGroupSpecification };
  tasks: CaseTask[] | null;
  featureFlags?: CaseFeatureFlags;
  category?: string;
}

/**
 * The metadata associated with a {@link TaskConfiguration}
 */
export interface TaskMetaData {
  messages: object;
  titleDisplaySpecification?: DisplaySpecification;
  featureFlags?: CaseFeatureFlags;
}

/**
 * Given an instance of [TaskConfiguration] return an array containing:
 *
 * 1. Any actions associated with the form, evaluated and ready for execution
 * 2. Any form handler function, evaluated and ready for execution
 *
 * As a side effect any global form functions associated with the config are
 * set at a window/global level
 * @param taskConfig
 */
export function compileFormRunnables(taskConfig: TaskConfiguration) {
  const result = [undefined, undefined, undefined];
  if (taskConfig.form.actions) {
    result[0] = window.eval(taskConfig.form.actions) || {};
  }
  if (taskConfig.form.formHandler) {
    const compiledHandler =
      window.eval(taskConfig.form.formHandler) || undefined;
    if (compiledHandler && compiledHandler.handler) {
      result[1] = compiledHandler.handler;
    }
    if (compiledHandler && compiledHandler.context) {
      result[2] = compiledHandler.context;
    }
  }
  if (taskConfig.form.globalFormFunctions) {
    _.set(
      window,
      'globalFormFunctions',
      window.eval(taskConfig.form.globalFormFunctions) || {}
    );
  }
  return result;
}
